<template>
    <div class="mt-6 md:mt-16 mx-6 md:mx-16 mb-6 md:mb-16 bg-black custom-min-height">

        <ul class="flex justify-between px-5 pt-3 text-3xl uppercase font-bold tracking-wider">
            <li><a href="#"><span v-on:click.prevent="$emit('close', {path: $route.matched[0].path.replace(':id', $route.params.id)})">Close</span></a></li>
            <li><a href="#" v-on:click.prevent="$emit('save')">Save</a></li>
        </ul>

        <div class="mt-8 px-8 md:px-20">
            <h2 class="font-bold text-40px uppercase tracking-wider block text-center">{{title}}</h2>

            <slot></slot>

        </div>

    </div>
</template>

<script>
    export default {
        name: 'StepSettingsWrapper',
        props: {
            title: {
                type: String,
                default: 'No title'
            }
        }
    }
</script>