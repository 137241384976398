<template>
    <step-settings-wrapper title="Print Settings" v-on:close="onClose" v-on:save="onSave">
        <div class="mt-1 max-w-lg mx-auto">

            <div class="pb-2 pt-5 border-b border-gray-custom-3 clearfix">
                <div class="float-left text-3xl uppercase font-bold tracking-wider">
                    Print Button
                </div>
                <div class="float-right pt-1">
                    <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="printEnabled" :value="preset.printSettings.printEnabled" v-model="preset.printSettings.printEnabled"></toggle-button>
                </div>
            </div>

            <div class="pb-2 pt-5 border-b border-gray-custom-3 clearfix">
                <div class="float-left text-3xl uppercase font-bold tracking-wider">
                    Auto Print
                </div>
                <div class="float-right pt-1">
                    <toggle-button :labels="toggleButtonSettings.labels" :color="toggleButtonSettings.color" :switch-color="toggleButtonSettings.switchColor" :width="toggleButtonSettings.width" :height="toggleButtonSettings.height" :margin="toggleButtonSettings.margin" name="autoPrint" :value="preset.printSettings.autoPrint" v-model="preset.printSettings.autoPrint"></toggle-button>
                </div>
            </div>

        </div>
    </step-settings-wrapper>
</template>

<script>
    // @ is an alias to /src
    import StepSettingsWrapper from '@/components/StepSettingsWrapper.vue'
    import { ToggleButton } from 'vue-js-toggle-button'

    export default {
        name: 'PrintSettings',
        props: {
            preset: Object,
            toggleButtonSettings: Object
        },
        data: function() {
            return {
                beforeEditing: {}
            }
        },
        components: {
            StepSettingsWrapper,
            ToggleButton
        },
        mounted: function() {
            this.beforeEditing = Object.assign({}, this.preset.printSettings);
        },
        methods: {
            onClose: function(toRoute) {
                //this.preset.printSettings = Object.assign({}, this.beforeEditing);
                let filesSelected = 0;
                this.$emit('close', 'printSettings', this.beforeEditing, filesSelected, toRoute);
            },
            onSave: function() {
                this.$emit('save', 'printSettings');
            }
        }
    }
</script>

<style scoped>
    @media (max-width: 374px) {
        .text-3xl {
            font-size: 24px;
            position: relative;
            top: 4px;
        }
    }
</style>